/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';

import { useTranslation } from "react-i18next";
import { getClientPassager, getColonne, getLignes, saveVeille } from "../../services/Veille/VeilleApi";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useParams } from 'react-router'

export default function Veille() {
    const [t] = useTranslation("global");
    const [lignesdata, setlignesdata] = useState([])
    const [P_concurrents, setP_concurrents] = useState([])
    const [listgroupe, setlistgroupe] = useState([])
    const listtype_client = ['Ancien actif', 'Ancien relancé', 'Nouveau']
    const [clientcode, setClientcode] = useState()
    const [clientNom, setClientNom] = useState()
    const [telephone_client, setTelephone_client] = useState()
    const [commentaire_client, setCommentaire_client] = useState()
    const [adresse_client, setAdresse_client] = useState()
    const [commercial_client, setCommercial_client] = useState()

    const { code } = useParams()
    useEffect(() => {
        getColonne().then(rescolone => {
            if (rescolone.status === 200) {

                setP_concurrents(rescolone.data)
                let list_groupe = new Set(rescolone.data.map(item => item.groupe));
                setlistgroupe([...list_groupe])

            }

        }


        )
        getLignes().then((reslignes) => {
            if (reslignes.status === 200) {
                setlignesdata(reslignes.data)
            }

        })
    }, [])
    useEffect(() => {
        if (code) {
            setClientcode(code.split('_')[0])
            setClientNom(code.split('_')[1])
        } else {
            getClientPassager().then((res) => {
                if (res.status === 200) {
                    setClientcode(res?.data?.code)
                }
            }).catch((err) => {
                toast(t('un problème client passager'), {
                    type: "error",
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            })

        }
    }, [code])

    const [entet, setentet] = useState({
        type_client: 'Nouveau',
        livraison_jour: 1,
        paiement_facture_jour: "Comptantespeces",
        paiement_ancienne_facture: "Neant"
    })
    const [listselected, setlistselected] = useState([])
    const handlchange_entet = (name, value) => {
        const copie_data = { ...entet }
        copie_data[name] = value;
        setentet(copie_data)
    }
    const change_table = (ligne, P_concurrents) => {
        const copie_data = [...listselected]
        const findselected_index = listselected.findIndex(item => item.code_ligne === ligne && item.code_colonne === P_concurrents);

        if (findselected_index === -1) {
            // ajouter

            const data = {
                client_code: clientcode,
                code_colonne: P_concurrents,
                code_ligne: ligne,
                reponse: 1,
                user_code: JSON.parse(localStorage.getItem('authentification_nomadis')).user.code
            }
            copie_data.push(data)
        } else {
            copie_data.splice(findselected_index, 1);


        }

        setlistselected(copie_data)
    }
    const save = async () => {

        const client_code = clientcode;
        const telephone = telephone_client;
        const commercial = commercial_client;
        const adresse = adresse_client;
        const commentaire = commentaire_client;
        const randomNum = Math.floor(Math.random() * 1000);
        const dateString = new Date().toISOString().replace(/[-:T.]/g, "").slice(0, 14);
        const code = `${client_code}_${dateString}_${randomNum}`;

        const listdata = listselected.map(element => {
            const copie = { ...element, ...entet }
            copie.code = code
            copie.telephone = telephone
            copie.commercial = commercial
            copie.adresse = adresse
            copie.commentaire = commentaire
            copie.code_colonne = parseInt(copie.code_colonne)
            copie.code_ligne = parseInt(copie.code_ligne)
            copie.client_nom = clientNom
            return copie
        });
        
       const res = await saveVeille({ veuilles: listdata })
        if (res.status == 200) {

            toast(t('données enregistré avec succès'), {
                type: "success",
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            window.location.reload();

        } else {
            toast(t('un problème'), {
                type: "error",
                position: toast.POSITION.BOTTOM_RIGHT,
            });


        }

        /*  const promises = listselected.map(element => saveVeille(element));
         
                 Promise.all(promises)
                     .then(results => {
                         results.forEach(resp => {
                             console.log('data2resp', resp);
                         });
                     })
                     .catch(error => {
                         // Handle error if any of the promises fail
                         console.error('Error occurred:', error);
                     });*/
    }



    const exportToExcel = () => {
        // Create a new workbook
        let wb = XLSX.utils.book_new();

        // Initialize the main sheet
        let mainWsData = [];
        let mainHeaders = ['type_client', 'livraison_jour', 'paiement_facture_jour', 'paiement_ancienne_facture'];
        mainWsData.push(mainHeaders);

        let mainRow = [
            entet.type_client,
            entet.livraison_jour,
            entet.paiement_facture_jour,
            entet.paiement_ancienne_facture
        ];
        mainWsData.push(mainRow);

        // Create the main worksheet
        let mainWs = XLSX.utils.aoa_to_sheet(mainWsData);
        XLSX.utils.book_append_sheet(wb, mainWs, 'f1');

        // Loop through each group to create sheets
        listgroupe.forEach(gp => {
            let groupeData = lignesdata.filter(item => item.groupe === gp);
            let groupeWsData = [];
            let groupeHeaders = [''];

            P_concurrents.filter(item => item.groupe === gp).forEach(item => {
                groupeHeaders.push(item.libelle);
            });
            groupeWsData.push(groupeHeaders);

            groupeData.forEach(cl => {
                let row = [cl.libelle];
                P_concurrents.filter(item => item.groupe === gp).forEach(p => {
                    let isChecked = listselected.find(item => item.code_ligne === cl.code && item.code_colonne === p.code);
                    row.push(isChecked ? 'Oui' : 'Non');
                });
                groupeWsData.push(row);
            });

            // Create and append the group worksheet
            let groupeWs = XLSX.utils.aoa_to_sheet(groupeWsData);
            XLSX.utils.book_append_sheet(wb, groupeWs, gp.toString());
        });

        // Finalize and trigger the download
        XLSX.writeFile(wb, 'ExportedData.xlsx');
    };






    return (

        <><ToastContainer /><div className="card">

            <div className="card-body">


                <div className="form-group ">

                    <label htmlFor="exampleInputname1">{t('Type Client')}</label>
                    <select className="form-control text-primary select2 w-100"
                        value={entet.value}
                        onChange={(e) => handlchange_entet('type_client', e.target.value)}
                    >
                        {listtype_client.map((item, index) => (
                            <option key={index} value={item}>
                                {t(item)}
                            </option>
                        ))}

                    </select>

                </div>
                {!code &&
                    <div className="form-group ">

                        <label htmlFor="exampleInputname1">{t('Nom Client')}</label>
                        <input className="form-control text-primary select2 w-100" type="text" value={clientNom} onChange={(e) => setClientNom(e.target.value)} />

                    </div>
                }

                <div className="form-group ">

                    <label htmlFor="exampleInputname1">{t('telephone')}</label>
                    <input className="form-control text-primary select2 w-100" type="text" value={telephone_client} onChange={(e) => setTelephone_client(e.target.value)} />

                </div>

                <div className="form-group ">
                    <label htmlFor="exampleInputname1">{t('adresse')}</label>
                    <input className="form-control text-primary select2 w-100" type="text" value={adresse_client} onChange={(e) => setAdresse_client(e.target.value)} />

                </div>

                <div className="form-group ">
                    <label htmlFor="exampleInputname1">{t('commercial')}</label>
                    <input className="form-control text-primary select2 w-100" type="text" value={commercial_client} onChange={(e) => setCommercial_client(e.target.value)} />
                </div>
              

                <div>
                    {listgroupe.map(gp => (
                        <div className="row table-responsive">
                            <table className="table table-bordered-black card-table table-striped tutorial-table" cellSpacing={0} width="100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {P_concurrents.filter(item => item.groupe === gp).map((item, index) => (
                                            <th key={index} value={item.code}>{item.libelle}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {lignesdata.filter(item => item.groupe === gp).map((cl, index) => (
                                        <tr key={index}>
                                            <td value={cl.code}>{cl.libelle}</td>
                                            {P_concurrents.filter(item => item.groupe === gp).map((p, i) => (
                                                <td key={i}>
                                                    <input type='checkbox' className="form-control fixed-width" onClick={() => change_table(cl.code, p.code)} checked={listselected.find(item => item.ligne === cl.code && item.P_concurrents === p.code)} />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                    ))}
                    <div className="row">
                        <button onClick={exportToExcel} className="btn btn-primary col-12">
                            {t('Exporter')}
                        </button>
                    </div>


                </div>





                <label className=" row mt-5">{t('Livraison du jour')}</label>

                <div className="row">
                    <div className="col-12 col-md-3 d-flex align-items-center">
                        <input
                            type="radio"
                            id="oui"
                            name="livraison_jour"
                            checked={entet.livraison_jour === 1}
                            onClick={() => handlchange_entet('livraison_jour', 1)}
                            className="col-2 col-md-3 text-primary " />
                        <label htmlFor="oui" className="ml-2">{t('Oui')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div> {/* Line break for small screens */}
                    <div className="col-12 col-md-3 d-flex align-items-center mt-2 mt-md-0">
                        <input
                            type="radio"
                            id="non"
                            name="livraison_jour"
                            checked={entet.livraison_jour === 0}
                            onClick={() => handlchange_entet('livraison_jour', 0)}
                            className="col-2 col-md-3 text-primary" />
                        <label htmlFor="non" className="ml-2">{t('Non')}</label>
                    </div>
                </div>



                <label className="row mt-5">{t('Paiement facture du jour')}</label>
                <div className="row">
                    <div className="col-12 col-md-3 d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Comptantespeces"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'Comptantespeces'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'Comptantespeces')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Comptantespeces" className="ml-2">{t('Comptant espèces')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Comptantcheque"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'Comptantcheque'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'Comptantcheque')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Comptantcheque" className="ml-2">{t('Comptant chèque')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Chequeantidate"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'Chequeantidate'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'Chequeantidate')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Chequeantidate" className="ml-2">{t('Chèque anti daté')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center">
                        <input
                            type="radio"
                            id="credit"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'credit'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'credit')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="credit" className="ml-2">{t('À crédit')}</label>
                    </div>
                </div>




                <label className=" row mt-5">{t('Paiement ancienne facture')}</label>
                <div className="row mt-5">
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Neant"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Neant'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Neant')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Neant" className="ml-2">{t('Néant')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Especes"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Especes'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Especes')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Especes" className="ml-2">{t('Espèces')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Chequeantidate"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Chequeantidate'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Chequeantidate')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Chequeantidate" className="ml-2">{t('Chèque anti daté')}</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center">
                        <input
                            type="radio"
                            id="Cheque"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Cheque'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Cheque')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Cheque" className="ml-2">{t('Chèque')}</label>
                    </div>
                </div>



                <label className="row mt-6">{t('Commentaire')}</label>
                <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center">
                    {/* <textarea
                        id="commentaire"
                        name="commentaire"
                        className="col-3 col-md-6 text-primary"
                        rows="6"  
                        cols="70">
                    </textarea> */}
                    <input className="form-control text-primary select2 w-100" type="text"  value={commentaire_client} onChange={(e) => setCommentaire_client(e.target.value)}/>

                    </div>
                </div>

                <div>
                    <button style={{ float: 'right' }} className="btn bg-primary mt-5 " onClick={save}>{t('Enregistrer')}</button>
                </div>


            </div>
        </div></>


    )
}
{/*  <tr>
                                        <td>{t('Abri')}</td>
                                        {P_concurrents.filter(item=>item.groupe === gp).map((p, i) => (
                                            <td key={i} className="fixed-width">
                                                <input type='checkbox' className="form-control fixed-width" onClick={() => change_table('Abri', p)} checked={listselected.find(item => item.ligne === 'Abri' && item.P_concurrents === p)} />
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{t('Promo')}</td>
                                        {P_concurrents.map((p, i) => (
                                            <td key={i} className="fixed-width">
                                                <input onClick={() => change_table('Promo', p)} checked={listselected.find(item => item.ligne === 'Promo' && item.P_concurrents === p)} type='checkbox' className="form-control fixed-width" />
                                            </td>
                                        ))}
                                    </tr>*/}