import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BrowserMultiFormatReader } from '@zxing/library';
import { toast, ToastContainer } from "react-toastify";
import isMobile from "is-mobile";
import Loader from "../../components/shared/Loader";
import ErrorAlert from "../../others/ErrorAlert";
import { getUserByCode } from "../../services/clients/ClientApi";

export default function AccessClient() {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const [cameraType, setCameraType] = useState("environment");
  const [scanning, setScanning] = useState(false);
  const videoRef = useRef(null);

  const toggleCamera = () => {
    setCameraType(cameraType === "environment" ? "user" : "environment");
  };

  const verifAutorisation = () => {
    setAlert(false);
    if (code !== "") {
      getUserByCode(code)
        .then((res) => {
          if (res.data.id !== null) {
            localStorage.setItem("codeclient", code);
            history.push({ pathname: "/clients" });
          } else {
            toast(t("messages.aucunclient"), { type: "error", position: toast.POSITION.BOTTOM_RIGHT });
          }
        })
        .catch(() => setAlert(true));
    }
  };

  const startScanner = async () => {
    const codeReader = new BrowserMultiFormatReader();
    try {
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices.find((device) =>
        cameraType === "environment"
          ? device.label.toLowerCase().includes("back")
          : device.label.toLowerCase().includes("front")
      )?.deviceId || videoInputDevices[0]?.deviceId;

      if (selectedDeviceId) {
        codeReader.decodeFromVideoDevice(selectedDeviceId, videoRef.current, (result) => {
          if (result) {
            setCode(result.getText());
            setScanning(false);
            codeReader.reset();
          }
        });
      } else {
        toast(t("No camera devices found."), { type: "error", position: toast.POSITION.BOTTOM_RIGHT });
      }
    } catch {
      toast(t("Error starting scanner."), { type: "error", position: toast.POSITION.BOTTOM_RIGHT });
    }
  };

  useEffect(() => {
    if (scanning) {
      startScanner();
    } else {
      const codeReader = new BrowserMultiFormatReader();
      codeReader.reset();
    }
  }, [scanning, cameraType]);

  return (
    <div className="h-75vh">
      <ToastContainer />
      <div className="page">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 mx-auto text-center">
              <div className="card">
                <div className="card-body p-6">
                  <div className="card-title text-center mb-5">{t("client.textaccess")}</div>
                  <div className="form-group row">
                    <input
                      type="text"
                      className={isMobile() ? "form-control col-8" : "form-control col-12"}
                      placeholder={t("client.inputtext")}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button className="btn btn-primary col-4" onClick={() => setScanning(!scanning)}>
                      {scanning ? "Close" : "Scan"}
                    </button>
                  </div>

                  {scanning && (
                    <div className="scanner-container">
                      <h1>{t("Barcode Scanner")}</h1>
                      <video ref={videoRef} style={{ width: "100%" }} />
                    </div>
                  )}

                  <div className="form-footer mt-1">
                    <button
                      onClick={() => verifAutorisation()}
                      className="btn btn-success btn-block"
                    >
                      {loading ? <Loader /> : t("client.valider")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert && <ErrorAlert t={t} />}
    </div>
  );
}
